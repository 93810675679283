import React, { useRef, useEffect, useState } from 'react';
import { Row, Col, Badge, Dropdown, DropdownButton, ButtonGroup, Form, InputGroup, Button, Modal } from 'react-bootstrap';

import axios from 'axios';

import CreateAccount from '../CreateAccount';

const FeedImage = (props) => {
  const { _id, video, categories, channels, description, views, votes, comments, source } = props.data;

  const searchParamsGet = new URLSearchParams(window.location.search);
  const transaction_id = searchParamsGet.get("aff_sub");
  const aff_sub1 = searchParamsGet.get("sub1");
  const aff_sub2 = searchParamsGet.get("sub2");
  const aff_sub3 = searchParamsGet.get("sub3");
  const aff_sub4 = searchParamsGet.get("sub4");
  const aff_sub5 = searchParamsGet.get("sub5");

  const [viewsCount, setViewsCount] = useState(views.length || 0)
  const [votesList, setVotesList] = useState(votes || [])

  const sortComments = comments && comments.length > 0 && comments.sort((a, b) => new Date(b.time.split('/').reverse().join('-')) - new Date(a.time.split('/').reverse().join('-')));
  const [commentsLimit, setCommentsLimit] = useState(5)
  const formatComments = sortComments && sortComments.length > 0 && sortComments.slice(0, commentsLimit);
  const [commentsList, setCommentsList] = useState(formatComments || [])

  const [voteLoading, setVoteLoading] = useState(false)

  const handleVote = async () => {
    try {
      const response = await axios.get(`https://gorillamediallc.com/betabbserver/api/feedvotecount/${_id}`)

      if(response.data.status === 'increase') {
        setVoteLoading(true)
        
        setVotesList((prev) => [...prev, response.data.ip])

        setTimeout(() => {
          setVoteLoading(false)
        }, 2000);
      } else {
        const updatedList = votesList.filter((item) => item !== response.data.ip)
        setVotesList(updatedList)
      }
    } catch (err) {
      setVoteLoading(false)
      console.log(err)
    }
  }

  // coments
  const user = localStorage.getItem('user')
  const parseUser = user ? JSON.parse(user) : ''
  const [comment, setComment] = useState('')

  const submitComment = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`https://gorillamediallc.com/betabbserver/api/feedcomment`, {
        comment: comment,
        user: parseUser?.username,
        id: _id
      });
  
      if(response.data.message === 'success' && response.data.comment) {
        setCommentsList((prev) => [response.data.comment, ...prev])
        setComment('')
      }

    } catch (err) {
      console.log(err);
    }
  };

  const [commentsLoading, setCommentsLoading] = useState(false)

  const loadMoreComments = () => {
    setCommentsLoading(true)
    const nextComments = commentsLimit + 5;
    const formatComments = comments.slice(0, nextComments);

    setTimeout(() => {
      setCommentsLimit(nextComments)
      setCommentsList(formatComments)

      setCommentsLoading(false)
    }, 1500);
  }

  // format Views
  const formatViews = (views) => {
        if (views < 1000) {
            return views.toString();
        } else {
            return `${Math.floor(views / 1000)}k`;
        }
  };

  // user ip
  const [ip, setIP] = useState("");

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  useEffect(() => {
    getData();
  }, []);

  // LINKS
  const copyLink = () => {
    const currentLink = window.location.origin+`/previewimage/${_id}`;

    navigator.clipboard.writeText(currentLink)
        .then(() => {
            alert('Link copied!');
        })
        .catch(err => {
            console.error('Failed to copy: ', err);
        });
  };

  // EMBED
  const [showEmbed, setShowEmbed] = useState(false);

  const handleCloseEmbed = () => setShowEmbed(false);
  const handleShowEmbed = () => setShowEmbed(true);

  const copyLinkInput = (type) => {
    
    let input;
    if(type === 'fixed') {
      input = document.getElementById('fixed-frame')
    } else if(type === 'responsive') {
      input = document.getElementById('responsive-frame')
    }

    navigator.clipboard.writeText(input.value)
        .then(() => {
            alert('Link copied!');
        })
        .catch(err => {
            console.error('Failed to copy: ', err);
        });
  };

  // Create Account
  const [showCreate, setShowCreate] = useState(false);

  const handleCloseCreate = () => setShowCreate(false);
  const handleShowCreate = () => setShowCreate(true);

  const createHandler = (info) => {
      console.log('axios call body empty')
  };
  
  // Show more Description
  const [isExpanded, setIsExpanded] = useState(false);

  const truncatedText = description.length > 200 ? description.slice(0, 200) + '...' : description;

  return (
    <>
    <Modal show={showCreate} centered keyboard={false} backdrop="static" className="custom-modalcontent">
            <Modal.Body className="position-relative">
                <Row className="g-0">
                    <Col sm={12} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/modal-image.jpg)`, backgroundSize: 'cover', height: "550px", padding: "0px" }}>
                        <center> <img src={`${process.env.PUBLIC_URL}/images/logo.png`}  style={{ height: '50px' }} alt="small logo" className="smalllogomodaltop" /> </center>

                        <CreateAccount onCreate={createHandler} />
                    </Col>
                </Row>
            </Modal.Body>
    </Modal>

    <Modal show={showEmbed} onHide={handleCloseEmbed} className="custom_adsmodal">
    <Modal.Header closeButton>
      <Modal.Title>Embed</Modal.Title>
    </Modal.Header>
    <Modal.Body>

      <h4>Fixed frame</h4>
      <InputGroup className="mb-3">
        <Form.Control
          id='fixed-frame'
          value={`<iframe src='${window.location.origin+`/previewimage/${_id}`}' frameborder='0' scrolling='no' allowfullscreen width='100%' height='1920'></iframe><p><a href='${window.location.origin+`/previewimage/${_id}`}'>via BBGifs</a></p>`}
        />
        <Button variant="outline-secondary" id="button-addon-fixed" onClick={() => copyLinkInput('fixed')}>
          COPY
        </Button>
      </InputGroup>

      <h4>Responsive frame:</h4>
      <InputGroup className="mb-3">
        <Form.Control
          id='responsive-frame'
          value={`<div style='position:relative;'><iframe src='${window.location.origin+`/previewimage/${_id}`}' frameBorder='0' scrolling='no' width='100%' height='1000' allowFullScreen></iframe></div><p><a href='${window.location.origin+`/previewimage/${_id}`}'>via BBGifs</a></p>`}
        />
        <Button variant="outline-secondary" id="button-addon-responsive" onClick={() => copyLinkInput('responsive')}>
          COPY
        </Button>
      </InputGroup>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCloseEmbed}>
        Close
      </Button>
    </Modal.Footer>
    </Modal>
    <div className='video-body'>
      <Row>
        <Col xl={11} xs={12}>
        <div className='video-body-preview position-relative'>
            {voteLoading && (
              <>
              <div className='vote-process'>
                <span style={{ color: '#d3007b'}}><i className="fa-solid fa-heart"></i></span>
              </div>
              </>
            )}
            <img
                src={`https://gorillamediallc.com/betabbserver/${video}`}
                width='100%'
                height='100%'
            />

            <div className='video-add-info custom-img-desc' style={{ bottom: '0px' }}>
                <div className='show-only-onpc'>
                    <small>{isExpanded ? description : truncatedText}</small>
                    <button className='show-more-button' onClick={() => setIsExpanded(!isExpanded)}>
                        [ {isExpanded ? 'Show Less' : 'Show More'} ]
                    </button>
                </div>
                <div>
                {categories.map((cat, index) => {
                    return (
                        <React.Fragment key={`${video}-${cat.label}-${index}`}>
                            <Badge bg='dark'>{cat.label}</Badge>
                        </React.Fragment>
                    )
                })}
                </div>

                <div>
                {channels.map((chan, index) => {
                    return (
                        <React.Fragment key={`${video}-${chan.label}-${index}`}>
                            <Badge bg='dark'>{chan.label}</Badge>
                        </React.Fragment>
                    )
                })}
                </div>
            </div>
         
          </div>
        </Col>
        <Col xl={1} xs={0}>
          <div className='video-options fixed-on-mobile'>
            {!parseUser ? (
              <>
              <button onClick={handleShowCreate}>
                {ip && votesList.includes(ip) ? <span style={{ color: '#d3007b'}}><i className="fa-solid fa-heart"></i></span> : <i className="fa-regular fa-heart"></i>}
                <p>{formatViews(votesList.length)}</p>
              </button>
              </>
            ) : (
              <>
              <button onClick={() => handleVote()}>
                {ip && votesList.includes(ip) ? <span style={{ color: '#d3007b'}}><i className="fa-solid fa-heart"></i></span> : <i className="fa-regular fa-heart"></i>}
                <p>{formatViews(votesList.length)}</p>
              </button>
              </>
            )}

            <DropdownButton
                as={ButtonGroup}
                key='start'
                id={`dropdown-button-drop-start`}
                drop='start'
                variant='secondary'
                title={<span><i className="fa-solid fa-share-from-square"></i></span>} 
                data-bs-theme="dark"
                className='share-button'
            >
                <Dropdown.Item eventKey="1" onClick={copyLink}>Copy Link</Dropdown.Item>
                <Dropdown.Item eventKey="2" onClick={handleShowEmbed}>Embed</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="3" href={`https://www.reddit.com/submit?title=${encodeURIComponent(description)}&url=${encodeURIComponent(window.location.origin+`/previewimage/${_id}`)}`} target='_blank'>Reddit</Dropdown.Item>
                <Dropdown.Item eventKey="4" href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(description)}&url=${encodeURIComponent(window.location.origin+`/previewimage/${_id}`)}`} target='_blank'>X</Dropdown.Item>
                <Dropdown.Item eventKey="5" onClick={copyLink}>Discord</Dropdown.Item>
            </DropdownButton>
          </div>
        </Col>
      </Row>

      <div className='post-buttons-custom mt-2'>
        <ButtonGroup className="d-flex flex-wrap">
          <a href={`${source}`} target='_blank' className="flex-fill">
            <Button variant="secondary" className="w-100">Source</Button>
          </a>
          <a href={`https://www.gcdates.com/cmp/2D2DH/8SB7WP/?sub2=BangBase&sub4=${parseUser?.email}`} target='_blank' className="flex-fill">
            <Button variant="secondary" className="w-100">Fuck Now</Button>
          </a>
          <a href={`https://www.gcdates.com/3J67C/2KCTMND/`} target='_blank' className="flex-fill">
            <Button variant="secondary" className="w-100">Live Sex</Button>
          </a>
          {/* <a href={`https://candy.ai`} target='_blank' className="flex-fill show-only-onpc">
            <Button variant="secondary" className="w-100">Virtual Girlfriend</Button>
          </a> */}
          <a href={`https://www.gcdates.com/cmp/2D2DH/8TK4X6/?sub1=${aff_sub1}&sub2=${aff_sub2}&sub4=${parseUser?.email}`} target='_blank' className="flex-fill show-only-onpc">
            <Button variant="secondary" className="w-100">Grow Your Cock</Button>
          </a>
        </ButtonGroup>
      </div>

      {/* {!parseUser && (
          <>
          <Button variant='danger' className='mt-2' size='sm' onClick={handleShowCreate}>Create account to post comments</Button>
          </>
      )} */}

      {/* <div className='reply-form custom-reply-body py-4'>
        {parseUser && ( 
          <>
          <Form onSubmit={submitComment}>
            <InputGroup className="mb-3">
            <Form.Control
              placeholder="Comment"
              aria-label="Comment"
              aria-describedby="basic-addon2"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Button variant="dark" type='submit' id="button-addon2">
              <i className="fa-solid fa-paper-plane"></i>
            </Button>
            </InputGroup>
          </Form>
          </>
        )}

        {commentsList && commentsList.length === 0 ? (
          <>
          <p>No comments!</p>
          </>
        ) : (
          <>
          {comments.length > commentsLimit && (
            <>
            <Button variant='dark' size='sm' onClick={() => loadMoreComments()}>{commentsLoading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'Load more comments'}</Button>
            </>
          )}

          {commentsList.map((c, index) => {
            return (
              <div key={`${_id}-comment-${index}`} className='comment-preview'>
                  <b>{c.user}</b> - <small>{c.time}</small> <p>{c.comment}</p>
              </div>
            )
          })}
          </>
        )}
      </div> */}
    </div>
    </>
  );
};

export default FeedImage;
